export const highlights: Array<{ title: string, description: string }> = [
  {
    title: 'Intuitive and easy-to-use',
    description: 'No coding, CSS or HTML skills needed.',
  },
  {
    title: 'Single choice or multiple choice question',
    description: 'Allow users to select multiple options or only a single one.',
  },
  {
    title: 'Modern site compatibility',
    description: `The web part is developed as a SPFx component which means it's compatible with the modern sites (i.e. Communication and Modern Team sites).`,
  },
  {
    title: 'Configurable',
    description: `Wide range of settings: themes, poll opening time, poll closing time, hide results, colors, custom styling, etc.`,
  },
  {
    title: 'Secure',
    description:
      'The User Poll is hosted on your SharePoint environment and no data is shared are sent to our servers or shared with third parties.',
  },
  {
    title: 'Multilingual support',
    description: `Our User Poll comes with a translation file which means all labels and messages can be updated to any language. Doesn't matter what language you speak.`,
  },
];

export const images: Array<{ src: string, width: number, height: number }> = [
  {
    src: '/images/user-poll/up-01-min.jpeg',
    height: 995,
    width: 1591,
    title: 'User Poll - 1',
  },
  {
    src: '/images/user-poll/up-02-min.jpeg',
    height: 993,
    width: 1590,
    title: 'User Poll - 2',
  },
  {
    src: '/images/user-poll/up-03-min.jpeg',
    height: 993,
    width: 1590,
    title: 'User Poll - 3',
  },
  {
    src: '/images/user-poll/up-04-min.jpeg',
    height: 992,
    width: 1591,
    title: 'User Poll - 4',
  },
  {
    src: '/images/user-poll/up-05-min.jpeg',
    height: 994,
    width: 1590,
    title: 'User Poll - 5',
  },
  {
    src: '/images/user-poll/up-06-min.jpeg',
    height: 1281,
    width: 1590,
    title: 'User Poll - 6',
  },
];
